import "./App.css";

import React, { useState, useRef } from "react";

const Footer = (props) => {
  const partners = props.data.map((data) => (
    <li>
      <a href={data.link} target="_blank">
        {data.name}
      </a>
    </li>
  ));

  const emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const email = useRef();

  function submit(event) {
    console.log(email.current.value);

    if (String(email.current.value).match(emailRegEx)) {
      console.log("validated");
    } else {
      console.log("not valid");
      event.preventDefault();
      alert("Not valid email, please enter a valid email");
    }
  }

  return (
    <div className="footer">
      <div className="container">
        <div className="footer-flex">
          <div className="contacts">
            <div className="footer-logo-div">
              <img
                src={require("../img/footer-icons/Footer-logo.svg").default}
                className="footer-logo"
                alt=""
              />
            </div>
            <div className="contacts-flex" href="">
              <a
                href="https://www.google.com/maps/place/1202+Nova+Ct+%23102,+Victoria,+BC+V9B+0L9/"
                target="_blank"
              >
                <img
                  src={require("../img/footer-icons/Location.svg").default}
                  className="contact-icon"
                  alt=""
                />
              </a>
              <a
                href="https://www.google.com/maps/place/1202+Nova+Ct+%23102,+Victoria,+BC+V9B+0L9/"
                target="_blank"
              >
                {" "}
                <p className="contact-info">
                  103-1202 Nova Ct. ,<br /> Victoria BC Canada V9B 0T7{" "}
                </p>
              </a>
            </div>
            <div className="contacts-flex">
              <a href="tel:250-896-4038">
                <img
                  src={require("../img/footer-icons/Phone.svg").default}
                  className="contact-icon"
                  alt=""
                />
              </a>
              <a href="tel:250-896-4038">
                <p className="contact-info">+9053211366</p>
              </a>
            </div>
            <div className="contacts-flex">
              <a href="mailto:r2bfrontrowgroup@gmail.com">
                <img
                  src={require("../img/footer-icons/Email.svg").default}
                  className="contact-icon"
                  alt=""
                />
              </a>
              <a href="mailto:r2bfrontrowgroup@gmail.com">
                <p className="contact-info">r2bfrontrowgroup@gmail.com</p>
              </a>
            </div>
          </div>
          <div className="socials">
            <h2 className="footer-title">Socials</h2>
            <div className="socials-flex">
              <a href="https://www.instagram.com/raybarkwill" target="_blank">
                <img
                  src={require("../img/footer-icons/Instagram.svg").default}
                  className="socials-icon"
                  alt=""
                />
              </a>
              <a href="https://www.facebook.com/ray.barkwill" target="_blank">
                <img
                  src={require("../img/footer-icons/facebook.svg").default}
                  className="socials-icon"
                  alt=""
                />
              </a>
              <a
                href="https://ca.linkedin.com/in/raymondbarkwill2"
                target="_blank"
              >
                {" "}
                <img
                  src={require("../img/footer-icons/linkedin.svg").default}
                  className="socials-icon"
                  alt=""
                />
              </a>
              <a href="https://twitter.com/RayBarkwill2" target="_blank">
                <img
                  src={require("../img/footer-icons/twitter.svg").default}
                  className="socials-icon"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="footer-partners">
            <h2 className="footer-title">Partners</h2>
            {partners}
          </div>
          <div className="form">
            <h2 className="footer-title" id="footer">
              {" "}
              Contact Us
            </h2>

            <form
              action="https://formsubmit.co/r2bfrontrowgroup@gmail.com"
              method="POST"
            >
              <input type="text" name="_honey" style={{ display: "none" }} />
              <input type="hidden" name="_captcha" value="false" />

              <div className="form-flex">
                <div>
                  <p className="input-name">Name</p>
                  <input
                    className="text-input"
                    name="name"
                    type="text"
                    id="name"
                    required
                  />
                </div>
                <div>
                  <p className="input-name">Email</p>
                  <input
                    className="text-input"
                    name="email"
                    type="text"
                    id="email"
                    ref={email}
                    onChange
                    required
                  />
                </div>
              </div>
              <p className="input-name">Message</p>
              <textarea
                id="message"
                name="comments, questions"
                className="message"
                required
              />
              <br />

              <input
                onClick={submit}
                type="submit"
                className="submit"
                id="submit"
              />
            </form>
          </div>
        </div>
        <div className="footer-bottom">
          <p>
            © 2024 R2B Frontrow Group. All Rights Reserved. Built By{" "}
            <a href="https://xaama.tech/" target="_blank">
              Xamaa Developments
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
